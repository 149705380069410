<template>
  <div class="one-page" v-if="isOnePage">
    <div class="gap"></div>
    <a-tabs class="one-tabs" v-model:activeKey="activeKey" size="large">
      <a-tab-pane :key="1" :tab="$t('MN_TrainingProject_MHome')"></a-tab-pane>
      <!-- 培训项目 -->
      <a-tab-pane
        :key="2"
        :tab="$t('exam_approval')"
        v-if="showReview"
      ></a-tab-pane>
      <!-- 考试批阅 -->
      <a-tab-pane :key="3" :tab="$t('mine.homework_approval')"></a-tab-pane>
      <!-- 作业批阅 -->
    </a-tabs>
  </div>
  <template v-if="activeKey === 1">
    <OtherBanner :type="13" />

    <section class="filter-box" id="pageTop">
      <div class="content">
        <!-- 项目分类 -->
        <TreeFilter
          v-if="hasProject"
          :name="$t('project.project_classify')"
          :dataSource="folderData"
          :treeData2="folderTreeData"
          :treePath2="folderTreePath"
          @onChange="folderChange"
          class="filter-lang"
        />
        <!-- 实施分类 -->
        <!-- <TreeFilter
          v-if="hasPlan"
          :name="$t('project.implement_classify')"
          :dataSource="folderData2"
          @onChange="folderChange2"
          class="filter-lang"
        /> -->
        <!-- 项目状态 -->
        <ListFilter
          :name="$t('Pub_Lab_ProjectStatus')"
          :dataSource="projectStatus"
          @onChange="projectStatusChange"
          class="filter-lang"
        />
        <!-- 完成状态 -->
        <ListFilter
          only
          :name="$t('Pub_Lab_FinishStatus')"
          :dataSource="passStatus"
          @onChange="passStatusChange"
          class="filter-lang"
        />
      </div>
    </section>
    <section class="toolbar">
      <div class="content">
        <div class="crumbs" v-if="(hasProject == hasPlan) == true"></div>
        <div class="crumbs" v-else>
          {{ $t("current_position") }}：
          <!-- 当前位置： -->
          <span
            v-for="(item, index) in folderCrumbs"
            :key="item.id"
            @click="crumbsClick(item, index)"
          >
            {{ item.name }}
            <i>></i>
          </span>
        </div>
        <div class="search">
          <a-input-search
            v-model:value.trim="searchVal"
            :placeholder="$t('please_enter_keywords')"
            style="width: 220px"
            enter-button
            allow-clear
            @pressEnter="search"
          />
          <!-- 请输入课程名称 -->
        </div>
      </div>
    </section>

    <section class="project">
      <div class="content">
        <a-spin :spinning="projectLoading">
          <template v-if="projectData.length">
            <div class="cards clearfix">
              <div
                class="item fl"
                v-for="item in projectData"
                :key="item.projectId"
              >
                <ProjectCard :dataSource="item" />
              </div>
            </div>
            <div class="page-Wrap">
              <a-pagination
                show-quick-jumper
                :show-total="(total) => $t('XB_InTotal', [pageTotal])"
                :defaultPageSize="20"
                v-model:current="currentPage"
                :total="pageTotal"
                @change="pageChange"
              >
                <template #itemRender="{ type, originalElement }">
                  <a class="page-a" v-if="type === 'prev'">{{
                    $t("cm_pre")
                  }}</a>
                  <a class="page-a" v-else-if="type === 'next'">{{
                    $t("cm_next")
                  }}</a>
                  <renderVNode v-else :vnode="originalElement"></renderVNode>
                </template>
              </a-pagination>
            </div>
          </template>
          <a-empty v-else style="padding: 50px 0" />
        </a-spin>
      </div>
    </section>
  </template>
  <template v-if="activeKey === 2">
    <div class="one-page-comtainer">
      <examreview />
    </div>
  </template>
  <template v-if="activeKey === 3">
    <div class="one-page-comtainer">
      <homework />
    </div>
  </template>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import TreeFilter from "@/components/new/filter/TreeFilter.vue";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import OtherBanner from "@/components/banner/OtherBanner.vue";
import ProjectCard from "@/components/project/ProjectCard.vue";
import { projectList, projectFolder, planFolder } from "@/api/project";
import { getReviewList } from "@/api/exam";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
import examreview from "@/views/mine/components/review/index.vue";
import homework from "@/views/mine/components/homework.vue";
export default {
  components: {
    TreeFilter,
    ListFilter,
    ProjectCard,
    renderVNode,
    OtherBanner,
    examreview,
    homework,
  },
  setup() {
    const { t: $t } = useI18n();
    const route = useRoute();
    const store = useStore();
    // 目录
    let folderId = Number(route.query.folderId || 0);
    let folderId2 = 0;
    let folderCrumbs = ref([
      {
        id: 0,
        name: $t("CM_All"),
        // 全部
      },
    ]);
    let hasProject = ref(false);
    let hasPlan = ref(false);
    let folderData = ref([]);
    let folderData2 = ref([]);
    let isOnePage = ref(false);
    let showReview = ref(false);

    const getNewObj = (data) => {
      return JSON.parse(JSON.stringify(data));
    };

    let tempFolderCrumbs = [];
    let tempFolderData = [];
    let tempFolderPath = [];
    const traverseTree = (data) => {
      let str = `"id":${folderId},`;
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let strItem = JSON.stringify(item);
        if (strItem.includes(str)) {
          tempFolderCrumbs.push(item);
          tempFolderData.push([
            {
              id: tempFolderData.length == 0 ? 0 : item.id,
              name: $t("CM_All"),
            },
            ...data,
          ]);
          tempFolderPath.push(i);
          if (item.id == folderId && item.list && item.list.length) {
            tempFolderData.push([
              {
                id: tempFolderData.length == 0 ? 0 : item.id,
                name: $t("CM_All"),
              },
              ...item.list,
            ]);
            tempFolderPath.push(-1);
          }
          if (item.id != folderId && item.list && item.list.length) {
            traverseTree(item.list);
          }
          break;
        }
      }
    };

    const getReview = () => {
      getReviewList({ status: 0 }).then((res) => {
        if (res.data.list) {
          showReview.value = true; // 考试批阅
        }
      });
    };

    watch(
      () => store.getters.companyInfo,
      (company) => {
        isOnePage.value = [1, 2, 3].includes(company.mobileModule);
        if (company.menu) {
          if (company.menu.indexOf("project") !== -1) {
            hasProject.value = true;
            projectFolder().then((res) => {
              if (res.ret === 0) {
                folderData.value = res.data || [];
                if (folderId) {
                  traverseTree(getNewObj(folderData.value));
                  folderCrumbs.value = tempFolderCrumbs;
                  folderCrumbs.value = getNewObj(tempFolderCrumbs);
                  folderTreeData.value = getNewObj(tempFolderData);
                  folderTreePath.value = getNewObj(tempFolderPath);
                }
              }
            });
          }
          if (company.menu.indexOf("plan") !== -1) {
            hasPlan.value = true;
            planFolder().then((res) => {
              if (res.ret === 0) {
                folderData2.value = res.data || [];
              }
            });
          }
          if (company.menu.includes("examreview")) {
            getReview();
          }
        }
      },
      { immediate: true }
    );

    let folderTreeData = ref([]);
    let folderTreePath = ref([]);
    const folderChange = ({ activeId, crumbs, reload, treeData, treePath }) => {
      folderId = activeId;
      folderCrumbs.value = crumbs;
      folderTreeData.value = treeData;
      folderTreePath.value = treePath;
      getProjectList();
    };

    const folderChange2 = ({ activeId, crumbs }) => {
      folderId2 = activeId;
      if (!hasProject.value) folderCrumbs.value = crumbs;
      getProjectList();
    };

    // 项目状态
    const projectStatus = [
      {
        name: $t("Pub_Tab_NotStart"), // 未开始
        id: 1,
      },
      {
        name: $t("XB_UnderWay"), // 进行中
        id: 2,
      },
      {
        name: $t("XB_HasEnded"), // 已结束
        id: 3,
      },
    ];
    let projectStatusActive = [0];
    const projectStatusChange = (arr) => {
      projectStatusActive = arr;
      getProjectList();
    };

    // 完成状态
    const passStatus = [
      { id: 1, name: $t("CM_Completed") }, // 已完成
      { id: 2, name: $t("CM_NotCompleted") }, // 未完成
    ];
    let passStatusActive = [0];
    const passStatusChange = (arr) => {
      passStatusActive = arr;
      getProjectList();
    };

    // 面包屑
    const crumbsClick = (data, index) => {
      let dom = document.getElementById(`tree_${index}_${data.id}`);
      dom.click();
    };

    // 搜索
    const searchVal = ref(""),
      search = () => {
        currentPage.value = 1;
        getProjectList();
      };

    // 课程
    const projectData = ref([]),
      projectLoading = ref(true),
      currentPage = ref(1),
      pageTotal = ref(1),
      getProjectList = (scroll = false) => {
        projectLoading.value = true;
        projectList({
          page: currentPage.value,
          pageSize: 20,
          name: searchVal.value,
          folderId: folderId,
          folderId2: folderId2,
          status: projectStatusActive[0] === 0 ? [] : projectStatusActive,
          complete: passStatusActive[0] === 0 ? [] : passStatusActive,
        }).then((res) => {
          projectLoading.value = false;
          if (res.ret === 0) {
            pageTotal.value = res.data.totals;
            projectData.value = res.data.list || [];
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      pageChange = (n) => {
        currentPage.value = n;
        getProjectList(true);
      };
    getProjectList();
    const activeKey = ref(1);

    return {
      hasProject,
      hasPlan,
      folderData,
      folderTreeData,
      folderTreePath,
      folderData2,
      folderCrumbs,
      folderChange,
      folderChange2,
      crumbsClick,
      projectStatus,
      projectStatusChange,
      passStatus,
      passStatusChange,
      searchVal,
      search,
      getProjectList,
      projectData,
      projectLoading,
      currentPage,
      pageTotal,
      pageChange,
      activeKey,
      isOnePage,
      showReview,
    };
  },
};
</script>

<style lang="less" scoped>
.one-page {
  .mixinWrap();
  .gap {
    height: 20px;
  }
  .one-tabs {
    background-color: #fff;
  }
}
.one-page-comtainer {
  .mixinWrap();
  min-height: 700px;
}
.filter-box {
  background-color: @color-page-gray;
  padding-bottom: 14px;
  .content {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
    .mixinWrap();
    // ::v-deep(.filter-lang) {
    //   .label {
    //     width: 78px !important;
    //   }
    //   .values {
    //     width: calc(100% - 88px) !important;
    //   }
    // }
  }
}

.toolbar {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    .mixinFlex(space-between; center);
    padding: 20px 0 16px;
    .crumbs {
      width: calc(100% - 250px);
      font-size: 14px;
      color: #999;
      span {
        cursor: pointer;
        i {
          padding: 0 2px;
        }
        &:hover {
          color: @color-theme;
        }
        &:last-child {
          .noevents();
          i {
            display: none;
          }
          &:hover {
            color: #999;
          }
        }
      }
    }
  }
}

.project {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    padding: 22px 0 42px;
    .cards {
      .item {
        margin-right: 24px;
        margin-bottom: 24px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

.search {
  padding: 5px 0;
  .mixinFlex(flex-end);

  ::v-deep(.ant-input-search) {
    padding-right: 1px;
    border-radius: 4px 0 0 4px;
  }

  ::v-deep(.ant-btn) {
    border-radius: 0 4px 4px 0;
  }
}
</style>
